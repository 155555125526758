<template>
  <div class="mobile-container">
    <div class="new-list-item">
      <router-link :to="{path: '/question'}" class="new-item">
        <div class="icon img-cover dot">
          <img src="../../assets/images/news-nav-icon-1.png" />
        </div>
        <div class="info">
          <div class="name">常见问题</div>
          <div class="detail">
            <!-- 了解商城最新资讯，实时掌握网站动态 -->
          </div>
        </div>
      </router-link>
      <router-link :to="{path: '/notice'}" class="new-item">
        <div class="icon img-cover dot">
          <img src="../../assets/images/news-nav-icon-2.png" />
        </div>
        <div class="info">
          <div class="name">站内消息</div>
          <div class="detail">点击查看更多</div>
        </div>
      </router-link>
      <router-link :to="{path: '/contact'}" class="new-item">
        <div class="icon img-cover">
          <img src="../../assets/images/news-nav-icon-3.png" />
        </div>
        <div class="info">
          <div class="name">在线客服</div>
          <div class="detail">24小时在线客服</div>
        </div>
      </router-link>
      <div v-if="websiteInfo.Wx && (websiteInfo.Wx.code || websiteInfo.Wx.num) && websiteInfo.Wx.status == 1" class="new-item">
        <div class="icon img-cover">
          <img src="../../assets/images/news-nav-icon-4.png" />
        </div>
        <div class="info">
          <div class="name">官方微信</div>
          <div class="detail">{{ websiteInfo.Wx.num }}</div>
        </div>
        <div v-if="websiteInfo.Wx.code" class="img img-cover"><img :src="websiteInfo.Wx.code" /></div>
      </div>
      <div v-if="websiteInfo.Qq && websiteInfo.Qq.num && websiteInfo.Qq.status == 1" class="new-item" @click="handleCopy(websiteInfo.Qq)">
        <div class="icon img-cover">
          <img src="../../assets/images/news-nav-icon-5.png" />
        </div>
        <div class="info">
          <div class="name">官方QQ</div>
          <div class="detail">{{ websiteInfo.Qq.num }}</div>
        </div>
      </div>
    </div>
    <mobileTabBar :tabBarKey="tabBarKey" />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import mobileTabBar from '@/components/mobileTabBar'
 
export default {
  name: "News",
  components: {
    mobileTabBar
  },
  data() {
    return {
      tabBarKey: 'news',
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  mounted () {

  },
  methods: {
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
  },
};
</script>

<style lang="less" scoped>
.new-list-item {
  padding: .38rem .32rem 0;
  .new-item {
    width: 100%;
    height: 1rem;
    margin-bottom: .52rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      width: 1rem;
      height: 1rem;
      position: relative;
      &.dot {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          width: .16rem;
          height: .16rem;
          background-color: #eb5757;
          border: .04rem solid #fff;
          border-radius: 50%;
          box-sizing: content-box;
        }
      }
    }
    .info {
      flex: 1;
      margin-left: .24rem;
      overflow: hidden;
      .name {
        width: 100%;
        height: .36rem;
        line-height: .36rem;
        font-size: .3rem;
        font-weight: 500;
        color: #333;
      }
      .detail {
        width: 100%;
        height: .36rem;
        margin-top: .08rem;
        line-height: .36rem;
        font-size: .24rem;
        color: #bdbdbd;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .img {
      margin-left: .24rem;
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
